import React, { useState, useEffect } from "react";
import Portal from "../components/graphics/portal";
import { usePrivy } from "@privy-io/react-auth";
import { PrivyClient } from "@privy-io/server-auth";
import { GetServerSideProps } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { LoadingSpinner } from "../components/loadingSpinner";

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  const cookieAuthToken = req.cookies["privy-token"];

  if (!cookieAuthToken) {
    return { props: {} };
  }

  const PRIVY_APP_ID = process.env.NEXT_PUBLIC_PRIVY_APP_ID;
  const PRIVY_APP_SECRET = process.env.PRIVY_APP_SECRET;
  const client = new PrivyClient(PRIVY_APP_ID!, PRIVY_APP_SECRET!);

  try {
    const claims = await client.verifyAuthToken(cookieAuthToken);
    console.log({ claims });

    return {
      redirect: { destination: "/dashboard", permanent: false }
    };
  } catch (error) {
    res.setHeader("Set-Cookie", "privy-token=; Max-Age=0; Path=/");
    return { props: {} };
  }
};

export default function LoginPage() {
  const { login, authenticated } = usePrivy();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authenticated) {
      setIsLoading(true);
      router.push("/dashboard");
    }
  }, [authenticated, router]);

  return (
    <>
      <Head>
        <title>Login · SiBorg Quests</title>
      </Head>

      <main className="flex min-h-screen min-w-full">
        <div className="flex bg-privy-light-blue flex-1 p-6 justify-center items-center">
          {isLoading ? (
            <LoadingSpinner size={48} className="text-violet-600" />
          ) : (
            <div>
              <div>
                <Portal style={{ maxWidth: "100%", height: "auto" }} />
              </div>
              <div className="mt-6 flex justify-center text-center">
                <button
                  className="bg-violet-600 hover:bg-violet-700 py-3 px-6 text-white rounded-lg"
                  onClick={() => {
                    setIsLoading(true);
                    login();
                  }}
                >
                  Log in
                </button>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
}
